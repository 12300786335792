<template>
  <aside class="aside">
    <nav>
      <ul>
        <li><a href="https://lk.exportcenter.ru/ru/main">Главная</a></li>
        <li class="active"><a href="https://lk.exportcenter.ru/ru/educational-services">Образовательные сервисы</a></li>
        <li><a href="https://lk.exportcenter.ru/ru/products">Продукция</a></li>
        <li><a href="https://lk.exportcenter.ru/ru/company">Компания</a></li>
        <li><a href="https://lk.exportcenter.ru/ru/users">Сотрудники</a></li>
        <li><a href="https://lk.exportcenter.ru/ru/user-profile">Профиль</a></li>
      </ul>
    </nav>
    <div class="aside__support">
      <div class="aside__support-label">Служба поддержки</div>
      <div class="aside__support-data">digital@exportcenter.ru</div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Sidebar"
}
</script>

<style scoped>

</style>